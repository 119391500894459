import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "./firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import NavigationBar from "./HomePageComponents/NavigationBar";
import Footer from "./HomePageComponents/Footer";
import styles from "./HomePage.module.css";

const HomePage = () => {
  const [movies, setMovies] = useState([]);
  const [visibleLetters, setVisibleLetters] = useState(0);
  const navigate = useNavigate();
  const welcomeText = "Welcome to BookNow!";
  const animatedLetters = welcomeText.split("");

  useEffect(() => {
    // Animated Welcome Text
    let timer = setInterval(() => {
      setVisibleLetters((prev) => prev + 1);
      if (visibleLetters >= welcomeText.length) {
        clearInterval(timer);
      }
    }, 100);
    return () => clearInterval(timer);
  }, [visibleLetters, welcomeText.length]);

  useEffect(() => {
    // Fetch movies
    const fetchMovies = async () => {
      const querySnapshot = await getDocs(collection(db, "Movies"));
      const moviesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMovies(moviesData);
    };

    fetchMovies();
  }, []);

  useEffect(() => {
    // Scroll-based animations
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.fadeIn);
          } else {
            entry.target.classList.remove(styles.fadeIn);
          }
        });
      },
      { threshold: 0.1 }
    );

    const sections = document.querySelectorAll(`.${styles.scrollSection}`);
    sections.forEach((section) => observer.observe(section));

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <NavigationBar />
      
      <div className={styles.homepage}>
      <style>
@import url('https://fonts.googleapis.com/css2?family=Parkinsans:wght@300..800&display=swap');
</style>
        {/* Welcome Section */}
        <div className={`${styles.scrollSection} ${styles.welcomeSection}`}>
          <h1 className={styles.welcomeHeading}>
            {animatedLetters.map((char, index) => (
              <span
                key={index}
                style={{ opacity: index < visibleLetters ? 1 : 0 }}
              >
                {char}
              </span>
            ))}
          </h1>
          <p className={styles.description}>
            Discover and book tickets for the latest movies near you.<br />
            click here to host your events.<br />
            learn more...
          </p>
          {/* Scroll Down Symbol */}
  <div className={styles.scrollDown}>
    <span>&#8595;</span>
  </div>
        </div>

        {/* Movies Section */}
        <div className={`${styles.scrollSection} ${styles.moviesSection}`}>
          <h4 className={styles.h4}>Upcoming Movies on BookNow</h4>
          <ul className={styles.movieList}>
            {movies.map((movie) => (
              <li
                key={movie.id}
                className={styles.movieItem}
                onClick={() => navigate(`/movie/${movie.id}`)}
              >
                <img
                  src={movie.posterUrl}
                  alt={`${movie.title} Poster`}
                  className={styles.moviePoster}
                />
                <div className={styles.movieDetails}>
                  <h2 className={styles.movieTitle}>{movie.title}</h2>
                  <p className={styles.movieVenue}>Venue: {movie.venue}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
